import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconEmail.module.css';

const IconEmail = props => {
    const { className, rootClassName } = props;
    const classes = classNames(rootClassName || css.root, className);

    return (
        <svg
            className={classes}
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
        >
            <g>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    fill="none"
                    d="M16 12C16 14.2091 14.2091 16 12 16C9.79086 16 8 14.2091 8 12C8 9.79086 9.79086 8 12 8C14.2091 8 16 9.79086 16 12ZM16 12V13.5C16 14.8807 17.1193 16 18.5 16V16C19.8807 16 21 14.8807 21 13.5V12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21H16"
                ></path>
            </g>
        </svg>


    );
};

const { string } = PropTypes;

IconEmail.defaultProps = {
    className: null,
    rootClassName: null,
};

IconEmail.propTypes = {
    className: string,
    rootClassName: string,
};

export default IconEmail;
