import React, { useState, useRef, useEffect } from 'react';
import { Heading } from '../../components';
import { richText } from '../../util/richText';
import { FormattedMessage } from '../../util/reactIntl';
import css from './ListingPage.module.css';

const MIN_LENGTH_FOR_LONG_WORDS = 20;

const SectionTextExpandableMaybe = props => {
  const { text, heading, showAsIngress = false } = props;
  const textClass = showAsIngress ? css.ingress : css.text;
  const content = richText(text, {
    longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
    longWordClass: css.longWord,
    breakChars: '/',
  });
  const [expanded, setExpanded] = useState(false);
  const contentRef = useRef();

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  const maxAltura = 136

  const containerStyle = {
    maxHeight: expanded ? 'none' : `${maxAltura}px`,
    overflow: 'hidden',
  };

  return text ? (
    <div className={css.sectionText}>
      {heading ? (
        <Heading as="h4">
          {heading}
        </Heading>
      ) : null}
      <div style={containerStyle}>
        <p className={textClass} ref={contentRef}>{content}</p>
      </div>
      {contentRef.current && contentRef.current.clientHeight > maxAltura && !expanded && (
        <div className={css.readMoreBanner}>
          <button className={css.readMoreButton} onClick={toggleExpand}>
            <FormattedMessage id='ListingPage.showMore' />
          </button>
        </div>
      )}
    </div>
  ) : null;
};
export default SectionTextExpandableMaybe;
