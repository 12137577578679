import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconX.module.css';

const IconX = props => {
    const { className, rootClassName } = props;
    const classes = classNames(rootClassName || css.root, className);

    return (
        <svg
            className={classes}
            viewBox="0 0 301 271"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="m236 0h46l-101 115 118 156h-92.6l-72.5-94.8-83 94.8h-46l107-123-113-148h94.9l65.5 86.6zm-16.1 244h25.5l-165-218h-27.4z"
            />
        </svg>
    );
};

const { string } = PropTypes;

IconX.defaultProps = {
    className: null,
    rootClassName: null,
};

IconX.propTypes = {
    className: string,
    rootClassName: string,
};

export default IconX;
