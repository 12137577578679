import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconShare.module.css';

const IconShare = props => {
    const { className, rootClassName } = props;
    const classes = classNames(rootClassName || css.root, className);

    return (
        <svg
            className={classes}
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
        >
            <g>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    fill="none"
                    d="M20 13V17.5C20 20.5577 16 20.5 12 20.5C8 20.5 4 20.5577 4 17.5V13M12 3L12 15M12 3L16 7M12 3L8 7"
                ></path>
            </g>
        </svg>


    );
};

const { string } = PropTypes;

IconShare.defaultProps = {
    className: null,
    rootClassName: null,
};

IconShare.propTypes = {
    className: string,
    rootClassName: string,
};

export default IconShare;
